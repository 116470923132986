(function () {
  'use strict';

  angular
    .module('settings.users')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings.users', {
        abstract: true,
        url: '/users',
        templateUrl: 'settings/users/users.tpl.html'
      })
    ;
  }
}());
